import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VIDEO_TYPES } from '../config/const.config';
import MainLayout from '../layout/Main';
import HorizontalList from '../components/pages/HorizontalList';
import Loading from '../components/common/Loading';
import { searchData } from '../services/channelData.service';
import LandingPageData from '../static/landingPage';

const Search = ({ menuData, activePage, handlePageChange }) => {
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [data, setData] = useState({
    videos: [],
    movies: [],
    events: [],
    success: true,
    noData: false,
  });

  const localState = {
    timeout: null,
  };

  const updateSearchData = (val) => {
    setSearching(true);

    if (val === '') {
      setSearching(false);
      setData({
        videos: [],
        movies: [],
        events: [],
        success: true,
        noData: false,
      });
      return;
    }

    searchData(val)
      .then((res) => {
        if (window.document.getElementById('search-slug').text !== val) {
          return;
        }

        const { content } = res;
        const finalData = {
          videos: [],
          movies: [],
          events: [],
        };

        (content.videos || []).forEach((v) => {
          finalData.videos.push({
            id: v._id,
            title: v.title,
            description: v.description || '',
            shortDescription: v.short_description || '',
            hlsUrl: v.hls_url,
            poster: v.poster,
            posterH: v.poster_16_9,
            posterV: v.poster_9_16,
            startTime: v.start_date_time || '',
            endTime: v.end_date_time || '',
            duration: Number(v.duration || 0),
            genres: v.genres || '',
            category: v.category || '',
            channelId: Number(v.channel_id),
            director: v.director || '',
            actor1: v.actor1 || '',
            actor2: v.actor2 || '',
            actor3: v.actor3 || '',
            rating: v.rating,
            ratingSource: v.rating_source || '',
            season: Number(v.season),
            episode: Number(v.episode),
            srtUrl: v.srt_url || '',
            vttUrl: v.vtt_url || '',
            source: v.source || '',
            playDirectUrl: v.playDirectUrl || '',
            liveVastUrl: v.liveVastUrl || '',
            type: VIDEO_TYPES.VIDEO,
          });
        });
        (content.movies || []).forEach((v) => {
          finalData.movies.push({
            id: v._id,
            title: v.title,
            description: v.description || '',
            shortDescription: v.short_description || '',
            hlsUrl: v.hls_url,
            poster: v.poster,
            posterH: v.poster_16_9,
            posterV: v.poster_9_16,
            startTime: v.start_date_time || '',
            endTime: v.end_date_time || '',
            duration: Number(v.duration || 0),
            genres: v.genres || '',
            category: v.category || '',
            channelId: Number(v.channel_id),
            director: v.director || '',
            actor1: v.actor1 || '',
            actor2: v.actor2 || '',
            actor3: v.actor3 || '',
            rating: v.rating,
            ratingSource: v.rating_source || '',
            season: Number(v.season),
            episode: Number(v.episode),
            srtUrl: v.srt_url || '',
            vttUrl: v.vtt_url || '',
            source: v.source || '',
            playDirectUrl: v.playDirectUrl || '',
            liveVastUrl: v.liveVastUrl || '',
            type: VIDEO_TYPES.MOVIES,
          });
        });
        (content.events || []).forEach((v) => {
          finalData.events.push({
            id: v._id,
            title: v.title,
            description: v.description || '',
            shortDescription: v.short_description || '',
            hlsUrl: v.hls_url,
            poster: v.poster,
            posterH: v.poster_16_9,
            posterV: v.poster_9_16,
            startTime: v.start_date_time || '',
            endTime: v.end_date_time || '',
            duration: Number(v.duration || 0),
            genres: v.genres || '',
            category: v.category || '',
            channelId: Number(v.channel_id),
            director: v.director || '',
            actor1: v.actor1 || '',
            actor2: v.actor2 || '',
            actor3: v.actor3 || '',
            rating: v.rating,
            ratingSource: v.rating_source || '',
            season: Number(v.season),
            episode: Number(v.episode),
            srtUrl: v.srt_url || '',
            vttUrl: v.vtt_url || '',
            source: v.source || '',
            playDirectUrl: v.playDirectUrl || '',
            liveVastUrl: v.liveVastUrl || '',
            type: VIDEO_TYPES.EVENT,
          });
        });

        setData({
          ...finalData,
          success: true,
          noData:
            finalData.videos.length === 0 &&
            finalData.movies.length === 0 &&
            finalData.events.length === 0,
        });
        setSearching(false);
      })
      .catch(() => {
        setData({
          videos: [],
          movies: [],
          events: [],
          success: false,
          noData: true,
        });
        setSearching(false);
      });
  };

  const handleSearchChange = (e) => {
    clearTimeout(localState.timeout);
    setQuery(e.target.value);

    localState.timeout = setTimeout(() => {
      window.document.getElementById('search-slug').text = e.target.value;
      updateSearchData(e.target.value);
    }, 250);
  };

  return (
    <MainLayout
      menuData={menuData}
      activePage={activePage}
      handlePageChange={handlePageChange}
      copyRightText={LandingPageData.copyRight}
    >
      <div className="page-container search-page">
        <div className="page-content" id="page-content">
          <div className="search-bar">
            <input
              type="text"
              name="query"
              id="query"
              placeholder="Search Videos, Movies, Events"
              value={query}
              onChange={handleSearchChange}
              autoComplete="off"
              className="prj-element input-class"
              data-focus-left={false}
              data-focus-right={false}
              data-focus-up=".top-navigation .prj-element.active"
              data-focus-down=".media-element.prj-element"
            />
            <button type="button" className="hide" id="search-slug">
              search
            </button>
          </div>

          <div className="search-results prj-element" id="search-res">
            {searching && (
              <div className="loading">
                <Loading />
              </div>
            )}

            {query === '' && !searching && (
              <div className="searching">Search result will appear here</div>
            )}

            {data.noData && !searching && (
              <div className="searching">No data found</div>
            )}

            {data.videos.length > 0 && (
              <HorizontalList
                id={1}
                title="Videos"
                containerId="hl-1"
                videosCount={data.videos.length}
                type={VIDEO_TYPES.VIDEO}
                activePage=""
                activeSubPage=""
                videosList={data.videos}
                keyUpElement="#query"
                keyDownElement=".media-element.portrait.prj-element"
              />
            )}

            {data.movies.length > 0 && (
              <HorizontalList
                id={2}
                title="Movies"
                containerId="hl-2"
                videosCount={data.movies.length}
                type={VIDEO_TYPES.MOVIES}
                activePage=""
                activeSubPage=""
                videosList={data.movies}
                keyUpElement=".media-element.prj-element"
                keyDownElement=".media-element.prj-element"
              />
            )}

            {data.events.length > 0 && (
              <HorizontalList
                id={3}
                title="Events"
                containerId="hl-3"
                videosCount={data.events.length}
                type={VIDEO_TYPES.EVENT}
                activePage=""
                activeSubPage=""
                videosList={data.events}
                keyUpElement=".top-navigation .prj-element.active"
                keyDownElement=".media-element.prj-element"
              />
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

Search.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default Search;
