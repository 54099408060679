/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LandingPageData from '../../static/landingPage';
import MainLayout from '../../layout/Main';
import BgPlayer from '../common/BgPlayer';
import HomePageBgPlayer from '../../components/common/HomePageBgPlayer';
import PromoVideo from '../../assets/videos/promo.m4v'
const AboutUs = ({
  menuData,
  activePage,
  activePageLayout,
  handlePageChange,
  landingPageButton,
}) => {
  const [activeDropDown, setActiveDropDown] = useState();
  const [activeDropDownSection, setActiveDropDownSection] = useState();
  const [toggle, setToggle] = useState(false);
  const [toggleSection, setToggleSection] = useState(false);

  const openDropDown = (id) => {
    setActiveDropDown(id);
    setToggle(!toggle);
  };
  const openDropDownSection = (sectionId) => {
    setActiveDropDownSection(sectionId);
    setToggleSection(!toggleSection);
  };

  return (
    <MainLayout
      menuData={menuData}
      activePage={activePage}
      handlePageChange={handlePageChange}
      activePageLayoutType={activePageLayout.layout}
      copyRightText={LandingPageData.copyRight}
    >
      <div className="landing-page-main-container about-video-position">





        <div
          className="landing-page-main"
          style={{
            backgroundImage: `url('${LandingPageData.topSection.bg_img}')`,
          }}
        >
          
        {/* <div className="background-live-stream">
            <BgPlayer id="bg-player" source={process.env.REACT_APP_CHANNEL_WEBSITE_DATA} />
        </div> */}
          <div id="" />
          <div className="landing-page-container">
            <div className='main-row'>
              <div className='col-40'>
                <div className='logo-img'><img src={LandingPageData.topSection.image}/></div>
                <div className='main-faq'>
                {LandingPageData.sections && (
          <>
            <div className="dropdown-section ">
              {/* <h2>{LandingPageData.faq.main_title}</h2> */}
              <a href="/" className='watch-free' onClick={() => landingPageButton()}>Watch For Free</a>
              <div>
                <ul>
                  {LandingPageData.sections.section_details.map((data, sectionId) => (
                    <li className="drop-down-li">
                      <h3>
                        <h4 onClick={() => openDropDownSection(sectionId)}>
                          <span>{data.heading}</span>
                          <span
                            id="drop-down-svg-plus"
                            className={
                              activeDropDownSection === sectionId && toggleSection
                                ? 'cross-transition'
                                : ''
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="default-ltr-cache-v1ob21 e164gv2o4"
                              data-name="Plus"
                              alt="plus-svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11 11V2H13V11H22V13H13V22H11V13H2V11H11Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </h4>
                      </h3>
                      <div
                        id={`drop-down-show-section-${sectionId}`}
                        className={
                          activeDropDownSection === sectionId && toggleSection
                            ? 'dropdown-hidden-section-visible dropdown-hidden-section'
                            : 'dropdown-default'
                        }
                      >
                        <a href={data.link}><img src={data.image} className='section-about-image' /></a>
                        <span>{data.description}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="landing-page-underline-section" />
          </>
        )}
                </div>
              </div>
              <div className='col-50'>
              <BgPlayer id="bg-player" source={PromoVideo} />
                {LandingPageData.about_us && (
                  <div>
                    <div>
                      {LandingPageData.about_us.heading && (
                        <h1 style={{color: `#000`, textAlign: `center`}}>
                          {LandingPageData.about_us.heading}
                        </h1>
                      )}
                      {LandingPageData.about_us.description && (
                        <p>
                          {LandingPageData.about_us.description}
                        </p>
                      )}
                    </div>
                  </div>
                )}        


              </div>
            </div>
            {/* {LandingPageData.topSection.heading && (
              <h1>{LandingPageData.topSection.heading}</h1>
            )}
            {LandingPageData.topSection.sub_heading && (
              <p>{LandingPageData.topSection.sub_heading}</p>
            )}
            {LandingPageData.topSection.description && (
              <h3>{LandingPageData.topSection.description}</h3>
            )}
            {LandingPageData.topSection.button_text && (
              <button
                className="landing-page-explore-btn"
                type="button"
                onClick={() => landingPageButton()}
              >
                {LandingPageData.topSection.button_text}
              </button>
            )} */}


          </div>
        </div>
        <div className="landing-page-underline-section" />
        {LandingPageData.faq && (
          <>
            <div className="dropdown-section ">
              <h2>{LandingPageData.faq.main_title}</h2>
              <div>
                <ul>
                  {LandingPageData.faq.question_answer.map((data, id) => (
                    <li className="drop-down-li">
                      <h3>
                        <h4 onClick={() => openDropDown(id)}>
                          <span>{data.question}</span>
                          <span
                            id="drop-down-svg-plus"
                            className={
                              activeDropDown === id && toggle
                                ? 'cross-transition'
                                : ''
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="default-ltr-cache-v1ob21 e164gv2o4"
                              data-name="Plus"
                              alt="plus-svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11 11V2H13V11H22V13H13V22H11V13H2V11H11Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </h4>
                      </h3>
                      <div
                        id={`drop-down-show-section-${id}`}
                        className={
                          activeDropDown === id && toggle
                            ? 'dropdown-hidden-section-visible dropdown-hidden-section'
                            : 'dropdown-default'
                        }
                      >
                        <span>{data.answer}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="landing-page-underline-section" />
          </>
        )}

      </div>
    </MainLayout>
  );
};
AboutUs.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  activePageLayout: PropTypes.shape({
    layout: PropTypes.string,
    bgVideo: PropTypes.string,
  }).isRequired,
  handlePageChange: PropTypes.func.isRequired,
  landingPageButton: PropTypes.func.isRequired,
};

export default AboutUs;
