/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ menuData, handlePageChange, copyRightText }) => (
  <div className="footer-main-container">
    {/* <div className="footer-rows">
      <div className="footer-row-1">
        <div className="footer-links">
          <ul className="footer-links-ul">
            {menuData.map((item) => (
              <li onClick={() => handlePageChange(item.id)}>{item.title}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer-row-2">
        <div className="copy-right-text">{copyRightText}</div>
      </div>
    </div> */}
  </div>
);

Footer.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  handlePageChange: PropTypes.func.isRequired,
  copyRightText: PropTypes.string.isRequired,
};

export default Footer;
