/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LandingPageData from '../../static/landingPage';
import MainLayout from '../../layout/Main';
import HomePageBgPlayer from '../../components/common/HomePageBgPlayer';

import image11 from '../../assets/images/footer-logos/image11.jpg';
import image12 from '../../assets/images/footer-logos/image12.png';
import image8 from '../../assets/images/footer-logos/image08.png';
import image9 from '../../assets/images/footer-logos/image09.png';
import image10 from '../../assets/images/footer-logos/image10.png';
import vonLogo from '../../assets/images/footer-logos/von-logo.png';


const LandingPage = ({
  menuData,
  activePage,
  activePageLayout,
  handlePageChange,
  landingPageButton,
}) => {
  const [activeDropDown, setActiveDropDown] = useState();
  const [toggle, setToggle] = useState(false);

  const openDropDown = (id) => {
    setActiveDropDown(id);
    setToggle(!toggle);
  };


  return (
    <div>
      <MainLayout
      menuData={menuData}
      activePage={activePage}
      handlePageChange={handlePageChange}
      activePageLayoutType={activePageLayout.layout}
      copyRightText={LandingPageData.copyRight}
    >
      <div className="landing-page-main-container">
        <div
          className="landing-page-main"
          // style={{
          //   // backgroundImage: `url('${LandingPageData.topSection.bg_img}')`,
          //   // height: '100vh',
          // }}
        >
          
        <div className="background-live-stream">
                <HomePageBgPlayer id="bg-player" source={process.env.REACT_APP_CHANNEL_WEBSITE_DATA} />
        </div>
          {/* <div id="overlay" /> */}
          <div className="landing-page-container">
            {/* {LandingPageData.topSection.heading && (
              <h1>{LandingPageData.topSection.heading}</h1>
            )}
            {LandingPageData.topSection.sub_heading && (
              <p>{LandingPageData.topSection.sub_heading}</p>
            )}
            {LandingPageData.topSection.description && (
              <h3>{LandingPageData.topSection.description}</h3>
            )}
            {LandingPageData.topSection.button_text && (
              <button
                className="landing-page-explore-btn"
                type="button"
                onClick={() => landingPageButton()}
              >
                {LandingPageData.topSection.button_text}
              </button>
            )} */}
          </div>
        </div>
      </div>
    </MainLayout>

    <div className="watch-video">
      <h2>Empowering Talk, TV Shows and Entertainment for All.</h2>
      <h5>Where Vision Meets Volume!</h5>
      <p>Available On Multiple Devices</p>
      <a href="/" className='btn' onClick={() => landingPageButton()}>watch for free</a>
    </div>

    <div className='Footer-Box'>
      <div className="content">
        Download Any of Our Apps And Watch <span className='footer_text_span'>FREE</span> Content
      </div>
      <div className="content-end">
        <div className="icons_sec">
          <ul>
            <li><a href="https://play.google.com/store/apps/details?id=com.midwaychicago.wvonam" target="_blank"><img src={image12} alt="" /></a></li>
            <li><a href="https://apps.apple.com/us/app/wvon-1690am/id6446637454" target="_blank"><img src={image11} alt="" /></a></li>
          </ul>
          <ul>
            <li><a href="https://channelstore.roku.com/details/ef216eb284b05d359afd20ca8093939a/von-tv" target="_blank"><img src={image8} alt="" /></a></li>
            <li><a href="#"><img src={image9} alt="" /></a></li>
            <li><a href="https://www.amazon.com/WVON-1960-VON-TV/dp/B08KWMDDL7" target="_blank"><img src={image10} alt="" /></a></li>
          </ul>
        </div>
        <div className="von_logo">
          <img src={vonLogo} alt="" />
        </div>
      </div>
    </div>
    </div>
  );
};
LandingPage.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  activePageLayout: PropTypes.shape({
    layout: PropTypes.string,
    bgVideo: PropTypes.string,
  }).isRequired,
  handlePageChange: PropTypes.func.isRequired,
  landingPageButton: PropTypes.func.isRequired,
};

export default LandingPage;
