/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { VIDEO_TYPES } from '../../config/const.config';
import convertSecToTime from '../../utils/timeformat.util';

const Item = ({
  id,
  videoId,
  title,
  description,
  shortDescription,
  hlsUrl,
  poster,
  posterH,
  posterV,
  startTime,
  endTime,
  duration,
  progress,
  genres,
  category,
  channelId,
  director,
  actor1,
  actor2,
  actor3,
  rating,
  releaseDate,
  ratingSource,
  season,
  episode,
  srtUrl,
  vttUrl,
  source,
  playDirectUrl,
  liveVastUrl,
  type,
  dataFocusLeft,
  dataFocusRight,
  dataFocusUp,
  dataFocusDown,
  dataOnSelfFocus,
  handleShowDetailPage,
  dataOnPagination,
}) => {
  const showProgress = Math.floor((progress * 100) / duration);
  const isPortrait = type === VIDEO_TYPES.MOVIES;

  const fallbackImage = (error, titleName) => {
    const err = { ...error };
    const errorElement = window.document.getElementById(err.target.id);
    if (errorElement !== null) {
      errorElement.outerHTML = `<div class="error-text-img"><div class="error-title">${titleName}<div></div>`;
    }
    return err;
  };

  return (
    <div>
      <div
        id={id}
        className={`media-element ${
          isPortrait ? 'portrait' : 'landscape'
        } prj-element`}
        data-focus-left={dataFocusLeft || false}
        data-focus-right={dataFocusRight || false}
        data-focus-up={dataFocusUp || false}
        data-focus-down={dataFocusDown || false}
        data-on-self-focus={dataOnSelfFocus || false}
        data-focus-pagination={dataOnPagination}
        onClick={() => {
          handleShowDetailPage({
            id: videoId,
            title,
            description,
            poster,
            posterH,
            posterV,
            hlsUrl,
            genres,
            duration,
            category,
            channelId,
            director,
            actor1,
            actor2,
            actor3,
            rating,
            releaseDate,
            ratingSource,
            season,
            episode,
            srtUrl,
            vttUrl,
            source,
            playDirectUrl,
            liveVastUrl,
            isPortrait,
          });
        }}
        role="none"
      >
        <div className="img">
          <div className="overlay-grid" />
          <div className="img-container">
            <img
              id={`target-image-h-v-${videoId}`}
              src={isPortrait ? posterV : posterH}
              alt={title}
              loading="lazy"
              onError={(error) => fallbackImage(error, title)}
            />
          </div>
          {type === VIDEO_TYPES.VIDEO && (
            <div className="video-duration">{convertSecToTime(duration)}</div>
          )}
          {duration > 0 && showProgress > 0 && (
            <div className="progress-wrapper">
              <div className="progress-bar">
                <span
                  className="progress-bar-fill"
                  style={{ width: `${showProgress}%` }}
                />
              </div>
            </div>
          )}
        </div>

        {type !== VIDEO_TYPES.MOVIES && <p className="title">{title}</p>}
        {type === VIDEO_TYPES.VIDEO && (
          <p className="sub-title">{description}</p>
        )}
      </div>
    </div>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videoId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  hlsUrl: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  posterH: PropTypes.string.isRequired,
  posterV: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  genres: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  channelId: PropTypes.number.isRequired,
  director: PropTypes.string.isRequired,
  actor1: PropTypes.string.isRequired,
  actor2: PropTypes.string.isRequired,
  actor3: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  releaseDate: PropTypes.string.isRequired,
  ratingSource: PropTypes.string.isRequired,
  season: PropTypes.number.isRequired,
  episode: PropTypes.number.isRequired,
  srtUrl: PropTypes.string.isRequired,
  vttUrl: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  playDirectUrl: PropTypes.string.isRequired,
  liveVastUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dataFocusLeft: PropTypes.string.isRequired,
  dataFocusRight: PropTypes.string.isRequired,
  dataFocusUp: PropTypes.string.isRequired,
  dataFocusDown: PropTypes.string.isRequired,
  dataOnSelfFocus: PropTypes.string.isRequired,
  handleShowDetailPage: PropTypes.func.isRequired,
  dataOnPagination: PropTypes.string.isRequired,
};

export default Item;
