/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { APP_PAGES, PLATFORMS } from '../../config/const.config';
import logoImg from '../../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/von_tv_menu.png';
// import searchIcon from '../../assets/images/icons/search.png';
import scrollAppView from '../../utils/viewScroll.util';

const TopMenu = ({
  menuData,
  activePage,
  handlePageChange,
  activePageLayoutType,
}) => {
  const appName = process.env.REACT_APP_NAME;
  const menuDataLength = menuData.length;  
  const [showSideMenu, setshowSideMenu] = useState(false);
  // platform web state variable
  const [colorChange, setColorchange] = useState(false);
  // const navBarScroll = () => {
  //   const focusedElements = window.document.querySelectorAll(
  //     '.prj-element.focused'
  //   );
  //   console.log(focusedElements, '*****from Topnav');
  //   // const activeTab = focusedElements[0].id;
  //   if (focusedElements) {
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.paddingTop = '540px';
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.marginTop = '0';
  //   } else {
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.paddingTop = '540px';
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.marginTop = '0';
  //   }
  // };

  const handleScroll = () => {
    const focusedElements = window.document.querySelectorAll(
      '.prj-element.focused'
    );
    if (focusedElements.length > 0) {
      scrollAppView(focusedElements[0]);
      if (
        // window.document.querySelector('.live-video-container') &&
        activePageLayoutType !== 'grid' &&
        activePage !== 'search'
      ) {
        if (focusedElements[0].id === 'top-menu-0') {
          if (window.document.querySelector('.live-video-container')) {
            if (window.document.querySelectorAll('.page-container')) {
              window.document.querySelectorAll(
                '.page-container'
              )[0].style.marginTop = `788px`;
            }
            // if (
            //   window.document.querySelectorAll(
            //     '.page-container .page-content.has-bg-video'
            //   )
            // ) {
            //   window.document.querySelectorAll(
            //     '.page-container .page-content.has-bg-video'
            //   )[0].style.marginTop = '5px';
            // }
          }
        }
      }
    }
  };

  const openScreen = () => {
    if (PLATFORMS.WEB) {
      handlePageChange(APP_PAGES.LANDINGPAGE);
      if (window.document.getElementById('top-navigation')) {
        window.document
          .getElementById('top-navigation')
          .classList.add('top-menu-absolute');
      }
    }
  };

  const changeNavbarColor = () => {
    if (PLATFORMS.WEB) {
      if (window.scrollY >= 5) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    }
  };
  if (PLATFORMS.WEB) {
    window.addEventListener('scroll', changeNavbarColor);
  }

  const mobMenu = () => {
    setshowSideMenu(true);
  };
  const closeNavBar = () => {
    setshowSideMenu(false);
  };

  return (
    <nav className={
        colorChange
          ? 'nav top-navigation navbar colorChange'
          : 'nav top-navigation navbar'
      }
      id="top-navigation">
     <div className="container">
     <button
        type="button"
        className="hide"
        id="top-bar-tabs"
        onClick={handleScroll}
      >
        Scroll
      </button>
      {/* <button
        type="submit"
        onClick={mobMenu}
        className="my-button"
        id="my-button"
      >
        <svg viewBox="0 0 100 80" width="40" height="40">
          <rect width="100" height="20" />
          <rect y="30" width="100" height="20" />
          <rect y="60" width="100" height="20" />
        </svg>
      </button> */}
      <div className="nav-left menu-links">
        <div>
          {' '}
          <button
            type="submit"
            onClick={mobMenu}
            className="my-button"
            id="my-button"
          >
            <svg viewBox="0 0 100 80">
              <rect width="100" height="20" />
              <rect y="30" width="100" height="20" />
              <rect y="60" width="100" height="20" />
            </svg>
          </button>
        </div>
        <div onClick={() => openScreen()}>
          <a className="link brand-logo">
            <img
              className={`${colorChange ? 'brand-logo-scroll' : ''} ${
                activePage !== APP_PAGES.LANDINGPAGE ? 'brand-logo-home' : ''
              }`}
              src={logoImg}
              alt={appName}
            />
          </a>
        </div>
        <div
          className={showSideMenu ? 'tabs show-top-menubar' : 'tabs'}
          role="none"
          id="top-bar-tabs"
        >
          <button
            className="nav-close-btn"
            type="submit"
            // ng-click="vm.close()"
            title="Close Navigation"
            onClick={() => closeNavBar()}
          >
            {/* <span className="icon icon-close" /> */}
            <svg
              // width="24"
              // height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="default-ltr-cache-v1ob21 e164gv2o4"
              data-name="Plus"
              alt=""
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 11V2H13V11H22V13H13V22H11V13H2V11H11Z"
                fill="currentColor"
              />
            </svg>
          </button>
          {menuData.map((item, idx) => (
            <a
              id={`top-menu-${idx}`}
              className={`link menu-item prj-element ${
                activePage === item.id.toString() ? 'active focused' : ''
              }${
                colorChange || activePage !== APP_PAGES.LANDINGPAGE
                  ? 'scroll-top-menu'
                  : ''
              }`}
              key={`menu-${item.id}`}
              aria-hidden
              // onClick={() => handlePageChange(item.id)}              
              onClick={() => handlePageChange(item.id)}
              data-focus-left={idx === 0 ? false : `#top-menu-${idx - 1}`}
              data-focus-right={
                idx + 1 === menuDataLength
                  ? '#top-menu-search'
                  : `#top-menu-${idx + 1}`
              }
              data-focus-up={false}
              data-focus-down=".page-container .prj-element"
              data-on-self-focus="#top-bar-tabs"
            >
              {item.title}
            </a>
          ))}
          {/* <a href="/aboutus">About Dummy</a> */}
        </div>
      </div>
      {/* <button
        type="submit"
        onClick={mobMenu}
        className="my-button"
        id="my-button"
      >
        <svg viewBox="0 0 100 80" width="40" height="40">
          <rect width="100" height="20" />
          <rect y="30" width="100" height="20" />
          <rect y="60" width="100" height="20" />
        </svg>
      </button> */}
      <div className="nav-right">
        <a
          id="top-menu-search"
          className={`link icon prj-element ${
            activePage === APP_PAGES.SEARCH ? 'active focused' : ''
          }`}
          aria-hidden
          onClick={() => handlePageChange(APP_PAGES.SEARCH)}
          data-focus-left={`#top-menu-${menuDataLength - 1}`}
          data-focus-right="#top-menu-settings"
          data-focus-up={false}
          data-focus-down="input#query.prj-element.input-class"
        >
          {/* <img src={searchIcon} alt="Search" /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="53.223"
            // height="44.046"
            fill={`${
              colorChange || activePage !== APP_PAGES.LANDINGPAGE
                ? '#0B44CB'
                : '#ffffff'
            }`}
            viewBox="0 0 53.223 44.046"
          >
            <g id="search-24px-2" transform="translate(0 0)">
            {/* <g id="search-24px-2"> */}
              <path
                id="Path_8"
                data-name="Path 8"
                d="M0,0H44.046V44.046H0Z"
                // transform="translate(9.177 0)"
                fill="none"
              />
              {/* <path
                id="Path_9"
                data-name="Path 9"
                d="M25.941,23.188h-1.45l-.514-.5a11.963,11.963,0,1,0-1.285,1.285l.5.514v1.45l7.8,7.8a1.934,1.934,0,0,0,2.735-2.735Zm-11.011,0a8.259,8.259,0,1,1,8.259-8.259A8.248,8.248,0,0,1,14.93,23.188Z"
                transform="translate(-2.999 2.505)"
              /> */}
              <path
              id="Path_9"
              data-name="Path 9"
              d="M25.941,23.188h-1.45l-.514-.5a11.963,11.963,0,1,0-1.285,1.285l.5.514v1.45l7.8,7.8a1.934,1.934,0,0,0,2.735-2.735Zm-11.011,0a8.259,8.259,0,1,1,8.259-8.259A8.248,8.248,0,0,1,14.93,23.188Z"
              />
            </g>
          </svg>
        </a>
      </div>
     </div>
    </nav>
  );
};

TopMenu.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  activePageLayoutType: PropTypes.string.isRequired,
};

export default TopMenu;
