/* eslint-disable import/no-duplicates */
import landingImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/von_tv_menu.png';
// import sectionOneImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/Biz-Ability 1280x720-4.png';
import sectionOneImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/section1.png';
// import sectionTwoImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DMV BUSINESS SPOTLIGHT 1280X720.png';
import sectionTwoImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/section2.png';
// import sectionThreeImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionThreeImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/section3.png';
// import sectionFourImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DISABILITY OWNED CINEMA 1280X720.png';
import sectionFourImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/section4.jpg';
import sectionFiveImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionSixImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/NDI FRC 1280x720-2.png';
import sectionSevenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/2GI 1280X720-2.png';
import sectionEightImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionNineImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/NDI FRC 1280x720-2.png';
import sectionTenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/WEBINARS 1280X720.png';
import sectionElevenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DISABILITY OWNED CINEMA 1280X720.png';
import vonTvLogo from '../assets/images/about_logo.png';
import aboutUsBg from '../assets/images/about_us_bg.png';

const LandingPageData = {
  topSection: {
    // heading: 'Disability Owned TV',
    heading: 'VON TV',
    image: vonTvLogo,
    // sub_heading: 'Join today.',
    // description: 'The Business Channel for People with Disabilities',
    // description: 'Watch Now - Empowering Entertainment for All',
    description: 'VONtv, powered by WVON Radio, is a free streaming service that delivers empowering and culturally relevant content to the masses. For more than sixty years, WVON Radio has been the social conscience of Black Chicago, and VONtv continues this tradition. Our diverse content includes radio programming, TV shows, independent films, and special events, all celebrating the vibrant African American culture of Chicago.',
    button_text: 'Watch For Free!',
    // button_text: 'Where Vision Meets Volume!',
    bg_img: aboutUsBg,
  },
  // section_one: {
  //   heading: 'WVON Radio Programming',
  //   // heading: 'Nikki Powis Interviews Savvy Business Owners with Disabilities ',
  //   description: "Immerse yourself in the heart of Chicago with WVON Radio Programming on VON TV. Celebrating over five decades of being the social conscience of Black Chicago, these shows offer a mix of empowering dialogue and insightful discussions. Hit 'Play Video' for thought-provoking content that resonates.",
  //   // description: 'Biz- Ability with Nikki Powis',
  //   image: sectionOneImage,
  // },
  section_one: {
    heading:
      // 'DMV Spotlight Shines A Light on DMV Business Owners with Disabilities ',
      'Culturally Relevant Content',
    // description: 'DMV Spotlight ',
    description: `Discover a world of empowering and culturally rich stories with our independent films and shorts. Each film and short video showcase unique narratives and perspectives from producers and directors from around the globe. Enjoy our captivating TV series and shows that highlight the richness of African American life and experiences. From drama to comedy, every series promises engaging storytelling with a purpose.`,
    image: sectionTwoImage,
  },
  section_two: {
    heading:
      // 'US SBA Videos provides information to plan, start, and grow a business.',
      'Special Entertainment Events',
    // description: 'US Small Business Administration ',
    description: "Experience the vibrancy of African American culture with our special entertainment and live events. From live concerts to cultural festivals, VONtv brings Chicago’s dynamic events to your screen.  VONtv covers live political debates and produces insightful panel discussions that offer a mix of empowering dialogue and thought-provoking topics that resonate.",
    image: sectionThreeImage,
  },
  section_three: {
    heading:
      // 'National Disabilitiy Institute focuses on the financial health and well-being of people with disabilities.',
      'Midway Broadcasting Corporation',
    description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
      `MBC properties include WVON 1690, Chicago's legendary urban station for over six decades. Considered  the social conscience of Black Chicago, it’s talk format is recognized as a leading source of information and community empowerment. In 2007, MBC expanded its properties to include WRLL 1450, Chicago's home for Latino Independent Broadcasters.  In 2020, MBC launched the OTT Digital Platform, VONtv, the go to place for culturally relevant content.`,
    image: sectionFourImage,
  },
  // section_five: {
  //   heading: `Small Business Start Ups Features SBA Funded Success Stories`,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionFiveImage,
  // },
  // section_six: {
  //   heading: `Get Money Matters Insights From NDI's  Financial Resilience Center `,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionSixImage,
  // },
  // section_seven: {
  //   heading: `Meet 2Gether International's Founders with Disabilities`,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionSevenImage,
  // },
  // section_eight: {
  //   heading: 'Small Business Captial - Tips On How To Fund Your Business',
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionEightImage,
  // },
  // section_nine: {
  //   heading: `Get Money Matters Insights From NDI's...`,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionNineImage,
  // },
  // section_ten: {
  //   heading: `Watch NDI's 2022 Small Busines Confernece Sessions `,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionTenImage,
  // },
  // section_eleven: {
  //   heading: 'Disability Owned Cinema features Empowering Films and Docs',
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionElevenImage,
  // },
  faq: {
    main_title: 'Frequently Asked Questions',
    question_answer: [
      // {
      //   question: 'What is Disabilty Owned?',
      //   answer:
      //     'Disability Owned is the FREE business education and information streaming service for people with disabilities.  Watch original shows, educational videos, and inspiring profiles, that can help people with disabilities plan, start, and grow a business enterprise. ',
      // },
      {
        question: 'What is VONtv?',
        answer:`VONtv is a free streaming service powered by the legacy of WVON Radio, offering a diverse mix of live programs, films, TV series, and specials that embody the spirit and culture of Black Chicago.`,
      },
      // {
      //   question: 'How do I get started?',
      //   answer:
      //     'Watch Disability Owned for FREE on the web and on your Roku, Apple TV, Amazon Fire TV, iOS and Android devices. ',
      // },
      {
        question: 'How do I access VONtv?',
        answer:`You can watch VONtv for FREE on various devices, including Roku, Amazon Fire TV, Apple TV, tablets, computers, and mobile phones. Just download the VON TV app or visit our website to start streaming.`
      },
      // {
      //   question: 'Where can I watch?',
      //   answer:
      //     'Disability Owned is FREE to watch on your laptop,Roku TV, Apple TV, Amazon Fire TV, iOS and Android device. ',
      // },
      {
        question: 'Where can I watch VONtv?',
        answer:
          `VONtv is available for free streaming on a wide range of devices. Enjoy our content on your laptop, Roku TV, Apple TV, Amazon Fire TV, or through our mobile app on iOS and Android devices.`,
      },
      // {
      //   question: 'What can I watch on Disability Owned?',
      //   answer:
      //     'Disability Owned has an orginal shows, educational video, and inspiring profiles, and more. Watch for FREE on anytime on your favorite streaming device.',
      // },
      {
        question: 'What can I watch on VONtv?',
        answer:
          `VONtv offers original live programs, on-demand films, TV series, special entertainment events, and more, all reflecting the rich African American culture and spirit of Chicago.`,
      },
      // {
      //   question: 'Is Disability Owned Good For Everyone ',
      //   answer:
      //     'Disability Owned offer business education, information, and inspiration everyone can enjoy, even those without a disability or interest in being self-employed.',
      // },
      {
        question: 'Is VONtv suitable for everyone?',
        answer:
          `Absolutely! VONtv provides culturally relevant and empowering content for a broad audience. Our programming is designed to be inclusive, enlightening, and entertaining for everyone.`,
      },
    ],
  },


  sections: {
    section_details: [
      {
          heading:
            // 'DMV Spotlight Shines A Light on DMV Business Owners with Disabilities ',
            'Culturally Relevant Content',
          // description: 'DMV Spotlight ',
          description: `Discover a world of empowering and culturally rich stories with our independent films and shorts. Each film and short video showcase unique narratives and perspectives from producers and directors from around the globe. Enjoy our captivating TV series and shows that highlight the richness of African American life and experiences. From drama to comedy, every series promises engaging storytelling with a purpose.`,
          image: sectionTwoImage,
          link: "#"
        },
        {
          heading:
            // 'US SBA Videos provides information to plan, start, and grow a business.',
            'Special Entertainment Events',
          // description: 'US Small Business Administration ',
          description: "Experience the vibrancy of African American culture with our special entertainment and live events. From live concerts to cultural festivals, VONtv brings Chicago’s dynamic events to your screen.  VONtv covers live political debates and produces insightful panel discussions that offer a mix of empowering dialogue and thought-provoking topics that resonate.",
          image: sectionThreeImage,
          link: "#"
        },
        {
          heading:
            // 'National Disabilitiy Institute focuses on the financial health and well-being of people with disabilities.',
            'Midway Broadcasting Corporation',
          description:
          //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
            `MBC properties include WVON 1690, Chicago's legendary urban station for over six decades. Considered  the social conscience of Black Chicago, it’s talk format is recognized as a leading source of information and community empowerment. In 2007, MBC expanded its properties to include WRLL 1450, Chicago's home for Latino Independent Broadcasters.  In 2020, MBC launched the OTT Digital Platform, VONtv, the go to place for culturally relevant content.`,
          image: sectionFourImage,
          link: "https://www.midwaybroadcasting.com/"
        }
    ]
  },

  about_us: {
    heading: 'About Us',
    // description: `Disability Owned is a FREE business streaming service for people with disabilities. Watch Disability Owned is available to watch on your laptop, Roku TV, Apple TV, Amazon Fire TV, iOS or Android devices. Boston Media, LLC creators and managers of Disability Owned, is a member of the National Disability Institute's Community Navigator Pilot Program (CNPP). NDI's CNPP is funded in part through a grant from the U.S. Small Business Administrtion.`,
    description: `VONtv, powered by WVON Radio, is a free streaming service that delivers empowering and culturally relevant content to the masses. For more than sixty years, WVON Radio has been the social conscience of Black Chicago, and VONtv continues this tradition. Our diverse content includes radio programming, TV shows, independent films, and special events, all celebrating the vibrant African American culture of Chicago.`,
  },
  copyRight: 'Copyright © 2023 All rights reserved',
};

export default LandingPageData;
